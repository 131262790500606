<template>
    <div>
        <div class="search-bar-wrapper">
            <div class="relative search-bar-container">
                <input type="text" class="blog-search" placeholder="Search blogs" v-model="search">
                <div class="reset-text-button" @click="ClearSearchBar">
                    <i class="bi bi-x-circle-fill"></i>
                    <span id="tooltip">Clear Search</span>
                </div>
            </div>
        </div>
        <!-- Show all of the blogs when the search bar is empty -->
        <div class="blog-grid-container" v-if="search == ''">
            <div class="blog-wrapper" v-for="blog of blogs" :key="blog">
                <router-link :to="{ name: 'blog', params: { id: blog._id } }">
                    <div class="blog">
                        <div class="blog-image">
                            <!-- <img src="/images/blog-cover-image-placeholder.png"> -->
                            <!-- <img v-if="!blog.image" src="https://picsum.photos/300/200"> -->
                            <img v-if="blog.image" :src ="'data:image/jpeg;base64,' + blog.image" :alt="blog.title"/>
                            </div>
                        <div class="blog-content">
                            <div class="blog-title">{{blog.title}}</div>
                            <div class="blog-author-date-wrapper">
                                <div class="blog-author">{{blog.author}}</div>
                                <div class="round-dot"></div>
                                <div class="blog-date">{{blog.date}}</div>
                            </div>
                            <div class="blog-tags flex align-center" v-if="blog.category">
                                <div class="tag" v-for="tag in blog.category" :key="tag">
                                    {{tag}}
                                </div>
                            </div>
                            <div class="blog-summary" v-html="blog.body"></div>
                            <!-- <div class="read-more-button-wrapper">
                                <router-link class="button-text" :to="{ name: 'blog', params: {id: blog._id} }">Read More</router-link>
                                <i class="bi bi-arrow-right-short button-arrow"></i>
                            </div> -->
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
         <!-- Show the resulting blogs whe there is a search term -->
        <div class="blog-grid-container" v-if="search != ''">
            <div class="blog-wrapper" v-for="blog of SearchBlogs" :key="blog">
                <router-link :to="{ name: 'blog', params: { id: blog._id } }">
                    <div class="blog">
                        <div class="blog-image">
                            <!-- <img v-if="!blog.image" src="https://picsum.photos/300/200"> -->
                            <img v-if="blog.image" :src ="'data:image/jpeg;base64,' + blog.image" :alt="blog.title"/>
                        </div>
                        <div class="blog-content">
                            <div class="blog-title">{{blog.title}}</div>
                            <div class="blog-author-date-wrapper">
                                <div class="blog-author">{{blog.author}}</div>
                                <div class="round-dot"></div>
                                <div class="blog-date">{{blog.date}}</div>
                            </div>
                            <div class="blog-tags flex align-center" v-if="blog.category">
                                <div class="tag" v-for="tag in blog.category" :key="tag">
                                    {{tag}}
                                </div>
                            </div>
                            <div class="blog-summary" v-html="blog.body"></div>
                            <!-- <div class="read-more-button-wrapper">
                                <router-link class="button-text" :to="{ name: 'blog', params: {id: blog._id} }">Read More</router-link>
                                <i class="bi bi-arrow-right-short button-arrow"></i>
                            </div> -->
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- No Results Found -->
        <div class="no-results-wrapper" v-show="noResults">
            <div class="no-results-icon">
                <i class="bi bi-search"></i>
            </div>
            <div class="no-results-heading">
                No results found.
            </div>
            <div class="no-results-text">
                We can't find any item matching your search.
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";

export default {
    data: () => ({
        blogs: [],
        search: "",
        filterBlogType: "",
        serverBlogs: [],
        noResults: false
    }),
    created () {
        this.blogs = this.serverBlogs;
    },
    watch: {
        blogs () {
            this.CheckForFilteredBlogResults();
        },
        SearchBlogs () {
            this.CheckForFilteredBlogResults();
        }
    },
    computed: {
        SearchBlogs: function() {
            if (this.search != "") {
                return this.serverBlogs.filter((blog) => {
                    return blog.title
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            } else {
                return this.serverBlogs;
            }
        }
    },
    methods: {
        BlogFilter() {
            let tempData = JSON.parse(JSON.stringify(this.serverBlogs));

            if(this.filterBlogType != "") {
                tempData = tempData.filter((blog) => {
                    if (blog.category.length > 0) {
                        const lowerCase = blog.category.map(category => category.toLowerCase());
                        return lowerCase.includes(this.filterBlogType);
                    }
                })
            }

            this.blogs = tempData;
        },
        async GetBlogData() {
            try {
                const getBlogs = await axios.get(`https://${location.host}/api/blogs`);
                // console.log(getBlogs);

                if (getBlogs.status === 200) {
                    // Iterate over getBlogs.data (which is an array of objects)
                    for (let blog of getBlogs.data) {
                        this.serverBlogs.push(
                            {
                                _id: blog._id,
                                title: blog.title,
                                body: blog.summary,
                                author: blog.author,
                                category: blog.category.split(", "),
                                date: this.FormatDate(blog.date),
                                image : blog.image
                            }
                        );
                    }
                }

            } catch (error) {
                console.log(new Error(error));
            }
        },
        FormatDate(date) {
            const newDate = dayjs(new Date(date)).format("Do MMMM YYYY");
            return newDate;
        },
        CheckForFilteredBlogResults() {
            if (this.SearchBlogs.length === 0 && this.blogs.length === 0) {
                this.noResults = true;
            }
            if (this.blogs.length > 0 && this.SearchBlogs.length === 0) {
                this.noResults = true;
            }
            if (this.SearchBlogs.length > 0 && this.blogs.length === 0) {
                this.noResults = false;
                if (this.search === "") {
                    this.noResults = true;
                }
            }
            if (this.SearchBlogs.length > 0 && this.blogs.length > 0) {
                this.noResults = false;
            }
        },
        ClearSearchBar() {
            this.search = "";
        },
    },
    mounted: function () {
        this.$root.$on('filter-blogs', (type) => {
            this.filterBlogType = type;

            if (type == "all") {
                this.filterBlogType = "";
                this.blogs = this.serverBlogs;
            }
            else {
                this.BlogFilter();
            }
        });
        this.GetBlogData();
    }
}
</script>

<style scoped>
.blog-grid-container {
    display: grid;
    grid-template-columns: 100%;
    padding: 0 20px;
    grid-gap: 20px;
    justify-content: center;
    max-width: var(--max-width);
    margin: 0 auto;
}
.search-bar-wrapper {
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.search-bar-wrapper .search-bar-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}
.search-bar-wrapper .search-bar-container .blog-search {
    border: none;
    border-radius: 6px;
    padding: 25px 70px 25px 25px;
    width: 100%;
    max-width: 800px;
    z-index: 2;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.16);
    font-size: 18px;
    color: var(--text-dark-terniary);
    font-weight: 300;
    font-family: inherit;
    transition: box-shadow 0.4s ease;
}
.search-bar-wrapper .search-bar-container .blog-search:focus {
    outline: none !important;
    box-shadow: 0 3px 52px 0 #00bcc536 !important;
}
.search-bar-wrapper .search-bar-container .reset-text-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 25px;
    right: 14px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    color: var(--text-dark-secondary);
    font-size: 20px;
    z-index: 2;
}
.search-bar-wrapper .search-bar-container #tooltip {
    position: absolute;
    top: -20px;
    width: 100px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 3px;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: opacity 0.2s ease,
                transform 0.2s ease;
}
.search-bar-wrapper .search-bar-container .reset-text-button:hover #tooltip {
    opacity: 1;
    transform: translateY(0px);
}
.search-bar-wrapper .blog-search.icon {
    background-image: url("../../assets/search-icon.svg");
    background-size: 25px;
    background-repeat: no-repeat;
}
.blog-grid-container .blog-wrapper:hover .blog {
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.08);
    transform: scale(1.015);
}
.blog-grid-container .blog {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    column-gap: 40px;
    overflow: hidden;
    position: relative;
    padding: 40px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: box-shadow 0.3s ease,
                transform 0.2s ease;
}
.blog-grid-container .blog .blog-thumbnail {
    margin-bottom: 1rem;
    background-color: #ebfafb;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.blog-grid-container .blog .blog-image {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
}
.blog-grid-container .blog .blog-image img {
    width: 100%;
    max-width: 300px;
    border-radius: 6px;
    height: 200px;
    object-fit: cover;
}
.blog-grid-container .blog .blog-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 20px 40px auto;
    grid-row-gap: 10px;
    grid-column-start: 2;
    grid-column-end: 3;
    overflow: hidden;
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-title {
    font-size: 28px;
    font-weight: 500;
    grid-row-start: 1;
    grid-row-end: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-summary {
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-dark-terniary);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3;
    -webkit-box-orient: vertical;
    grid-row-start: 4;
    grid-row-end: 5;
}
.blog-grid-container .blog .blog-content .blog-tags {
    grid-row-start: 3;
    grid-row-end: 4;
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-tags .tag {
    display: inline-block;
    padding: 10px 15px;
    background-color: rgba(0, 188, 197, 0.1);
    text-transform: capitalize;
    color: var(--brand-primary-blue);
    font-weight: 500;
    margin-right: 10px;
    border-radius: 50px;
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-author-date-wrapper {
    display: flex;
    align-items: center;
    grid-row-start: 2;
    grid-row-end: 3;
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-author-date-wrapper .blog-author,
.blog-grid-container .blog-wrapper .blog .blog-content .blog-author-date-wrapper .blog-date {
    color: var(--text-dark-secondary);
}
.blog-grid-container .blog-wrapper .blog .blog-content .blog-author-date-wrapper .round-dot {
    background-color: var(--text-dark-secondary);
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 0 10px;
}
.no-results-wrapper {
    max-width: var(--max-width);
    margin: 100px auto 0 auto;
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    text-align: center;
}
.no-results-wrapper .no-results-icon i {
    font-size: 75px;
    color: var(--text-dark-secondary);
}
.no-results-wrapper .no-results-heading {
    font-size: 20px;
    font-weight: 500;
}
.no-results-wrapper .no-results-text {
    font-size: 20px;
    color: var(--text-dark-secondary);
    max-width: 300px;
}
@media only screen and (max-width: 768px) {
    .blog-grid-container .blog-wrapper {
        max-height: 476px;
    }
    .blog-grid-container .blog {
        grid-template-columns: 1fr;
        /* grid-template-rows: 1fr 1fr; */
        row-gap: 40px;
        padding: 15px;
    }
    .blog-grid-container .blog .blog-content {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
    }
    .blog-grid-container .blog-wrapper .blog .blog-content .blog-title {
        font-size: 20px;
    }
}
@media only screen and (min-width: 768px) {
    .blog-grid-container .blog-wrapper {
        max-height: 284px;
    }
}
</style>