<template>
    <div>
        <Header>
            <template v-slot:header-title>
                Blog
            </template>
        </Header>
        <section class="blog-grid-section">
            <div class="blog-grid-controls"></div>
            <div class="blog-grid-filter-controls">
                <div class="filter-control blog-type"
                     @click="FilterBlogs('all')"
                     :class="{active: showAll}">
                     All
                </div>
                <div class="filter-control blog-type"
                     @click="FilterBlogs('market')"
                     :class="{active: showMarket}">
                     Market
                </div>
                <div class="filter-control blog-type"
                     @click="FilterBlogs('technology')"
                     :class="{active: showTechnology}">
                     Technology
                </div>
            </div>
            <!-- #region Pagination Testing -->
            <div class="pagination-wrapper" v-if="showPagination">
                <!-- First Page, Previous Page -->
                <div class="pagination-control"><i class="bi bi-chevron-double-left"></i></div>
                <div class="pagination-control"><i class="bi bi-chevron-left"></i></div>
                <!-- Page Indexing -->
                <div class="pagination-control page-control active">1</div>
                <div class="pagination-control page-control">2</div>
                <div class="pagination-control page-control">3</div>
                <div class="pagination-control page-control">4</div>
                <div class="pagination-control page-control">5</div>
                <!-- Next Page, Last Page -->
                <div class="pagination-control"><i class="bi bi-chevron-right"></i></div>
                <div class="pagination-control"><i class="bi bi-chevron-double-right"></i></div>
            </div>
            <!-- #endregion Pagination Testing -->
            <BlogGrid></BlogGrid>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import BlogGrid from "../components/blog/BlogGrid.vue";

export default {
    components: { Header, BlogGrid },
    metaInfo() {
        return {
            title: 'Blog | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'Official news, features and announcements for all Illapa Cloud products.'
                },
                {
                    property: 'og:title',
                    content: 'Blog | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'Official news, features and announcements for all Illapa Cloud products.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/resources/blogs'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/resources/blogs'
                }
            ]
        }
    },
    data: () => ({
        showAll: true, showMarket: false, showTechnology: false,
        showPagination: false
    }),
    methods: {
        FilterBlogs(type) {
            switch(type) {
                case "all":
                    this.FilterAllBlogs();
                    break;
                case "market":
                    this.FilterMarketBlogs();
                    break;
                case "technology":
                    this.FilterTechnologyBlogs();
                    break;
                default:
                    break;
            }
        },
        FilterAllBlogs() {
            this.$root.$emit('filter-blogs', 'all');
            this.showAll = true;
            this.showMarket = false;
            this.showTechnology = false;
        },
        FilterMarketBlogs() {
            this.$root.$emit('filter-blogs', 'market');
            this.showMarket = true;
            this.showAll = false;
            this.showTechnology = false;
        },
        FilterTechnologyBlogs() {
            this.$root.$emit('filter-blogs', 'technology');
            this.showTechnology = true;
            this.showAll = false;
            this.showMarket = false;
        }
    }
}
</script>

<style scoped>
.blog-grid-section {
    background-color: #f4f7f9;
    padding-bottom: 160px;
}
.blog-grid-section .blog-grid-controls {
    position: relative;
    background-color: #fff;
    height: 70px;
    margin-bottom: 5px;
}
.blog-grid-section .blog-grid-filter-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 5px 0;
    margin-bottom: 20px;
    font-size: 18px;
}
.blog-grid-section .blog-grid-filter-controls .filter-control {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.15s ease, color 0.15s ease;
    user-select: none;
    border-radius: 4px;
}
.blog-grid-section .blog-grid-filter-controls .filter-control:hover {
    color: var(--brand-accent-blue);
}
.blog-grid-section .blog-grid-filter-controls .filter-control:not(:first-child) {
    margin-left: 10px;
}
.blog-grid-section .blog-grid-filter-controls .filter-control.active {
    color: var(--brand-accent-blue);
}
.blog-grid-section .blog-grid-filter-controls .filter-control::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--brand-accent-blue);
    border-radius: 50px;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s ease;
}
.blog-grid-section .blog-grid-filter-controls .filter-control:hover::after,
.blog-grid-section .blog-grid-filter-controls .filter-control.active::after {
    width: 40%;
}
/* Pagination Testing */
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 60px 20px 0;
    margin-bottom: 20px;
    text-align: right;
}
.pagination-wrapper .pagination-control {
    padding: 10px 17px;
    border-radius: 50px;
    color: var(--text-dark-primary);
    font-size: 22px;
    cursor: pointer;
}
.pagination-wrapper .pagination-control.page-control {
    background-color: white;
}
.pagination-wrapper .pagination-control.page-control:not(:last-child) {
    margin-right: 15px;
}
.pagination-wrapper .pagination-control.page-control.active {
    background-color: var(--dark-default);
    color: white;
}
</style>